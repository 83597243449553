import { request, parse } from '@/utils/request'

const statuses = async (token) => {
  const response = await request.get('/schedule/statuses', {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const ownSchedule = async (token, month, year) => {
  const response = await request.get('/profile/schedule', {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      month,
      year
    }
  })

  return parse(response)
}

const userSchedule = async (token, id, month, year) => {
  const response = await request.get(`/users/${id}/schedule`, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      month,
      year
    }
  })

  return parse(response)
}

const ownRequests = async (token, status, sort, limit) => {
  const response = await request.get('/profile/schedule/requests', {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      status,
      sort,
      limit
    }
  })

  return parse(response)
}

const userRequests = async (token, id, status, sort, limit) => {
  const response = await request.get(`/users/${id}/schedule/requests`, {
    headers: {
      'Authorization': `Bearer ${token}`
    },
    params: {
      status,
      sort,
      limit
    }
  })

  return parse(response)
}

const requestDay = async (token, data) => {
  const response = await request.post('/schedule/requests', { data }, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const approve = async (token, id) => {
  const response = await request.post(`schedule/requests/${id}/approve`, {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const reject = async (token, id) => {
  const response = await request.post(`schedule/requests/${id}/reject`, {}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

const update = async (token, userId, data) => {
  const response = await request.put(`users/${userId}/schedule`, { data }, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })

  return parse(response)
}

export { statuses, ownSchedule, userSchedule, ownRequests, userRequests, requestDay, approve, reject, update }