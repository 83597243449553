<template>
  <wrapper class="schedule">
    <app-header/>
    <container class="schedule__legend legend">
      <div class="legend__item legend__item_green">
        Рабочий
      </div>
      <div class="legend__item legend__item_red">
        Экспресс
      </div>
      <div class="legend__item legend__item_orange">
        Больничный
      </div>
      <div class="legend__item legend__item_blue">
        Выходной
      </div>
      <div class="legend__item legend__item_purple">
        Ремонт
      </div>
      <div class="legend__item legend__item_yellow">
        Отпуск
      </div>
    </container>
    <div class="schedule__workspace">
      <container class="schedule__requests requests">
        <div class="requests__skeleton skeleton" v-if="! requestsDownloaded">
          <div class="requests__skeleton-header skeleton-element"></div>
          <div class="requests__skeleton-element skeleton-element"></div>
          <div class="requests__skeleton-element skeleton-element"></div>
          <div class="requests__skeleton-element skeleton-element"></div>
          <div class="requests__skeleton-element skeleton-element"></div>
          <div class="requests__skeleton-element skeleton-element"></div>
        </div>
        <div class="requests__wrapper" v-if="requestsDownloaded">
          <div class="requests__header container-header">
            Мои заявки
          </div>
          <div class="requests__data-container requests__data-container_header">
            <div class="requests__data-cell requests__data-cell_header">
              Заявка
            </div>
            <div class="requests__data-cell requests__data-cell_header">
              Подтверждение
            </div>
          </div>
          <div class="requests__data-container" v-for="request in requests" :key="request.id">
            <div class="requests__data-cell">
              <div class="requests__date">
                {{ request.issuedDay }}
              </div>
              <div class="requests__schedule">
                {{ request.scheduleStatus }}
              </div>
            </div>
            <div
                class="requests__data-cell requests__status"
                :class="{
                  'requests__status_opened' : request.status === 'На рассмотрении',
                  'requests__status_completed' : ['Отклонено', 'Подтверждено'].indexOf(request.status) !== -1
                }"
            >
              {{ request.status }}
            </div>
          </div>
        </div>
      </container>
      <container class="schedule__calendar calendar">
        <div class="calendar__skeleton skeleton" v-if="! scheduleDownloaded">
          <div class="calendar__skeleton-header skeleton-element"></div>
          <div class="calendar__skeleton-element skeleton-element"></div>
        </div>
        <div class="calendar__wrapper" v-if="scheduleDownloaded">
          <div class="calendar__header container-header">
            Рабочий календарь
          </div>
          <v-date-picker
              class="calendar__datepicker" mode="date" is-expanded
              v-model="calendarDate" :attributes="calendarAttributes" :select-attribute="{ bar: 'gray' }"
              @dayclick="setDate"
          />
        </div>
      </container>
      <container class="schedule__control control">
        <div class="control__skeleton skeleton" v-if="! statusesDownloaded">
          <div class="control__skeleton-element skeleton-element"></div>
          <div class="control__skeleton-element skeleton-element"></div>
          <div class="control__skeleton-element skeleton-element"></div>
          <div class="control__skeleton-element skeleton-element"></div>
        </div>
        <div class="control__wrapper" v-if="statusesDownloaded">
          <div class="control__header container-header">
            Изменить график
          </div>
          <form action="#" method="post" class="control__form">
            <div class="control__form-input-box">
              <app-input
                  class="control__element" id="dateStart" label="Дата (От)" readonly
                  v-model:model-value="dateStart"
                  :input-class-list="[{ 'form-element-error' : fieldHasError('date_start') }]"
                  @focus="clearError"
              />
              <span class="form-caption-error" v-show="fieldHasError('date_start')" v-html="errorBag.date_start"></span>
            </div>
            <div class="control__form-input-box">
              <app-input
                  class="control__element" id="dateEnd" label="Дата (До)" readonly
                  v-model:model-value="dateEnd"
                  :input-class-list="[{ 'form-element-error' : fieldHasError('date_end') }]"
                  @focus="clearError"
              />
              <span class="form-caption-error" v-show="fieldHasError('date_end')" v-html="errorBag.date_end"></span>
            </div>
            <div class="control__form-select-box">
              <app-select
                  class="control__element" id="statusId" label="Тип"
                  :options="statuses" v-model:model-value="statusId"
                  :select-class-list="[{ 'form-element-error' : fieldHasError('status_id') }]"
                  @update="updateData" @focus="clearError"
              />
              <span class="form-caption-error" v-show="fieldHasError('status_id')" v-html="errorBag.status_id"></span>
            </div>
            <app-button class="control__button" button-style="button_action" @click.prevent="request">
              Изменить
            </app-button>
          </form>
        </div>
      </container>
    </div>
  </wrapper>
</template>

<script>
import Wrapper from '@/layouts/Wrapper'
import AppHeader from '@/components/AppHeader'
import Container from '@/layouts/Container'
import AppInput from '@/components/AppInput'
import AppSelect from '@/components/AppSelect'
import AppButton from '@/components/AppButton'
import { statuses, ownSchedule, ownRequests, requestDay } from '@/api/schedule'
import { isError } from '@/utils/request'
import { deserialize } from 'deserialize-json-api'
import { camelToSnake, scheduleStatusColor, parseDateToClassicString, parseDateToIsoString } from '@/utils/helpers'

export default {
  name: 'Schedule',
  components: {
    Wrapper,
    AppHeader,
    Container,
    AppInput,
    AppSelect,
    AppButton
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      nextDate: 'first',
      statusId: null,
      statuses: [],
      requests: [],
      statusesDownloaded: false,
      scheduleDownloaded: false,
      requestsDownloaded: false,
      calendarDate: null,
      calendarAttributes: [],
      errorBag: {}
    }
  },
  methods: {
    updateData(event) {
      this[event.field] = event.value
    },

    fieldHasError(field) {
      return Object.prototype.hasOwnProperty.call(this.errorBag, field)
    },

    clearError(event) {
      const field = camelToSnake(event.target.id)

      if (Object.prototype.hasOwnProperty.call(this.errorBag, field)) {
        delete this.errorBag[field]
      }
    },

    setDate(day) {
      const date = parseDateToClassicString(day.date)

      if (this.nextDate === 'first') {
        this.dateStart = date
      } else {
        this.dateEnd = date
      }

      this.nextDate = this.nextDate === 'first' ? 'second' : 'first'
    },

    async request() {
      this.errorBag = {}

      const response = await requestDay(this.$store.state.user.token, {
        status_id: this.statusId,
        date_start: parseDateToIsoString(this.dateStart),
        date_end: parseDateToIsoString(this.dateEnd)
      })

      if (isError(response.status)) {
        if (response.status === 422) {
          for (const [key, value] of Object.entries(response.data.errors.validation)) {
            for (const error of value) {
              const curKey = key.replace('data.', '')

              if (Object.prototype.hasOwnProperty.call(this.errorBag, curKey)) {
                this.errorBag[curKey] += `${error}<br>`
              } else {
                this.errorBag[curKey] = `${error}<br>`
              }
            }
          }
        } else {
          this.$notify({
            type: 'error',
            text: response.data.errors.title
          })
        }
      } else {
        this.requestsDownloaded = false
        this.requests = []
        this.downloadRequests()

        this.scheduleDownloaded = false
        this.calendarAttributes = []
        this.downloadSchedule()

        this.$notify({
          type: 'success',
          text: response.data.meta.message
        })
      }
    },

    async downloadStatuses() {
      const response = await statuses(this.$store.state.user.token)

      if (isError(response.status)) {
        return
      }

      deserialize(response.data).data.forEach((status, index) => {
        this.statuses.push({
          key: parseInt(status.id),
          value: parseInt(status.id),
          display: status.name,
          selected: index === 0
        })

        if (index === 0) {
          this.statusId = parseInt(status.id)
        }
      })

      this.statusesDownloaded = true
    },

    async downloadSchedule() {
      let response = await ownSchedule(this.$store.state.user.token)

      if (! isError(response.status)) {
        deserialize(response.data).data.forEach((scheduleDate) => {
          this.calendarAttributes.push({
            highlight: scheduleStatusColor(scheduleDate.status.slug),
            dates: scheduleDate.day
          })
        })
      }

      response = await ownRequests(this.$store.state.user.token, 'opened')

      if (! isError(response.status)) {
        deserialize(response.data).data.forEach((scheduleRequest) => {
          this.calendarAttributes.push({
            dot: scheduleStatusColor(scheduleRequest.schedule_status.slug),
            dates: scheduleRequest.issued_day
          })
        })
      }

      this.scheduleDownloaded = true
    },

    async downloadRequests() {
      const response = await ownRequests(this.$store.state.user.token, undefined, '-created_at', 5)

      if (isError(response.status)) {
        return
      }

      deserialize(response.data).data.forEach((request) => {
        this.requests.push({
          id: parseInt(request.id),
          issuedDay: parseDateToClassicString(new Date(request.issued_day)),
          status: request.status,
          scheduleStatus: request.schedule_status.name
        })
      })

      this.requestsDownloaded = true
    }
  },
  created() {
    this.downloadStatuses()
    this.downloadSchedule()
    this.downloadRequests()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

/* legend */
.legend {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 14px;
  padding: 20px;
}

.legend__item {
  font-size: 12px;
  color: #666;

  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 2px;
    border-radius: 100%;
  }

  &_green::before {
    background-color: #38a169;
  }

  &_red::before {
    background-color: #e53e3e;
  }

  &_orange::before {
    background-color: #dd6b20;
  }

  &_blue::before {
    background-color: #3182ce;
  }

  &_purple::before {
    background-color: #805ad5;
  }

  &_grey::before {
    background-color: #718096;
  }

  &_yellow::before {
    background-color: #d69e2e;
  }
}

/* workspace */
.schedule__workspace {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.requests, .calendar, .control {
  padding: 25px 20px 20px;
}

/* requests */
.requests__header {
  margin-bottom: 20px;
}

.requests__data-container {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}

.requests__data-cell {
  flex-basis: 50%;
}

.requests__date {
  margin-bottom: 5px;
}

.requests__data-container_header {
  font-size: 13px;
}

.requests__date {
  font-size: 14px;
}

.requests__schedule {
  font-size: 12px;
  color: $caption-text-color;
}

.requests__status {
  font-size: 14px;
}

.requests__status_opened {
  color: $secondary-links-color;
}

.requests__status_completed {
  color: #ccc;
}

/* calendar */
.calendar__header {
  margin-bottom: 20px;
}

.calendar__datepicker {
  border: none;
}

/* control */
.control__header {
  margin-bottom: 20px;
}

.control__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.control__element {
  width: 100%;
}

/* skeleton */
.requests__skeleton-header, .calendar__skeleton-header {
  display: inline-block;
  width: 127px;
  height: 26px;
  margin-bottom: 20px;
}

.requests__skeleton-element, .control__skeleton-element {
  flex-grow: 1;
  height: 50px;
}

.calendar__skeleton-element {
  width: 100%;
  height: 165px;
}

.requests__skeleton, .control__skeleton {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (min-width: 576px) {
  /* legend */
  .legend {
    gap: 25px;
    margin-bottom: 28px;
    padding: 25px 50px;
  }

  .legend__item {
    font-size: 15px;

    &::before {
      width: 14px;
      height: 14px;
    }
  }

  /* workspace */
  .schedule__workspace {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 28px;
  }

  .requests, .calendar, .control {
    padding: 25px 30px 30px;
  }

  /* requests */
  .requests {
    flex-basis: calc(50% - 14px);
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .requests__header {
    margin-bottom: 25px;
  }

  .requests__data-container {
    flex-direction: column;
  }

  .requests__data-container_header {
    display: none;
  }

  .requests__data-cell:first-child {
    margin-bottom: 5px;
  }

  .requests__date {
    font-size: 16px;
  }

  .requests__status {
    font-size: 16px;
  }

  /* calendar */
  .calendar {
    flex-basis: 100%;
    order: 3;
  }

  .calendar__header {
    margin-bottom: 25px;
  }

  /* control */
  .control {
    flex-basis: calc(50% - 14px);
    order: 2;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .control__header {
    margin-bottom: 25px;
  }

  .control__form {
    gap: 30px;
  }

  /* skeleton */
  .calendar__skeleton-element {
    height: 340px;
  }

  .control__skeleton {
    gap: 30px;
  }
}

@media screen and (min-width: 768px) {
  /* legend */
  .legend {
    gap: 15px;
    padding: 25px 20px;
  }

  /* workspace */
  .requests, .calendar, .control {
    padding: 25px 20px 30px;
  }

  /* requests */
  .requests {
    flex-basis: calc(percentage(220 / 740) - 14px);
  }

  /* calendar */
  .calendar {
    flex-basis: calc(percentage(520 / 740) - 14px);
    order: 2;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  /* control */
  .control {
    flex-basis: 100%;
    order: 3;
    border-radius: 0;
  }

  .control__form {
    flex-direction: row;
    gap: 15px;
  }

  .control__form-input-box, .control__form-select-box, .control__button {
    flex-basis: calc(25% - (15px * 3 / 4));
  }

  /* skeleton */
  .control__skeleton {
    flex-direction: row;
    gap: 15px;
  }
}

@media screen and (min-width: 992px) {
  /* legend */
  .legend {
    gap: 30px;
    padding: 25px 30px;
  }

  .legend__item::before {
    margin-right: 5px;
  }

  /* workspace */
  .requests, .calendar, .control {
    padding: 25px 30px 30px;
  }

  /* requests */
  .requests {
    flex-basis: calc(percentage(327 / 962) - 14px);
  }

  .requests__header {
    margin-bottom: 35px;
  }

  .requests__data-container {
    flex-direction: row;
  }

  .requests__data-container_header {
    display: flex;
  }

  .requests__data-cell:first-child {
    margin-bottom: 0;
  }

  .requests__data-container_header {
    font-size: 15px;
  }

  .requests__status {
    font-size: 15px;
  }

  /* calendar */
  .calendar {
    flex-basis: calc(percentage(635 / 962) - 14px);
  }

  /* control */
  .control__form {
    justify-content: space-between;
    gap: 30px;
  }

  /* skeleton */
  .control__skeleton {
    gap: 30px;
  }
}

@media screen and (min-width: 1200px) {
  /* legend */
  .legend {
    gap: 40px;
    padding: 25px 40px;
  }

  /* workspace */
  .requests, .calendar, .control {
    padding: 35px 40px 40px;
  }

  /* requests */
  .requests {
    flex-basis: calc(percentage(331 / 1154) - (28px * 2 / 3));
    order: 3;
  }

  .requests__status {
    font-size: 14px;
  }

  /* calendar */
  .calendar {
    flex-basis: calc(percentage(560 / 1154) - (28px * 2 / 3));
    order: 1;
  }

  /* control */
  .control {
    flex-basis: calc(percentage(253 / 1154) - (28px * 2 / 3));
    order: 2;
    border-radius: $border-radius;
  }

  .control__form {
    flex-direction: column;
  }

  .control__form-input-box, .control__form-select-box, .control__button {
    flex-basis: auto;
    flex-grow: 1;
  }

  /* skeleton */
  .control__skeleton {
    flex-direction: column;
  }
}
</style>